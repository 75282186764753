import startCase from 'lodash/startCase';
import camelCase from 'lodash/camelCase';

import { ToPascalCaseFunctionType } from './models';

export const toPascalCase: ToPascalCaseFunctionType = (str) =>
  startCase(camelCase(str)).replace(/ /g, '');

export const hasLongWords = (text: string, max: number = 15): boolean =>
  text?.split(' ').some((item: string) => item.length > max);

const stringUtils = {
  toPascalCase,
  hasLongWords,
};

export default stringUtils;
