import React, { FC } from 'react';
import Slider from 'react-slick';

import Button from 'common/Button';
import Icon from 'common/IconCustom';

import { IWithCarouselProps, ICarouselControlProps } from './model';
import 'components/PDCarousel/PDCarousel.scss';
import 'styles/common/slick-styles.scss';
import './WithCarousel.scss';
import { DefaultCarouselSettings } from './constants';

const CarouselControl = ({
  className,
  classes,
  onClick,
  ariaLabel,
  iconName,
}: ICarouselControlProps) => (
  <Button
    classes={classes}
    ariaLabel={ariaLabel}
    clickHandler={onClick}
    variant="secondary"
    disabled={className.includes('disabled')}
  >
    <Icon icon={iconName} />
  </Button>
);

const WithCarousel: FC<IWithCarouselProps> = ({
  slides,
  settings = {},
  slideComponent: SlideComponent,
  ariaNext,
  ariaPrev,
}) => {
  if (slides?.length > 1) {
    const defaultSettings = {
      ...DefaultCarouselSettings,
      prevArrow: (
        <CarouselControl ariaLabel={ariaPrev} iconName="chevron-left" classes="prev-btn" />
      ),
      nextArrow: (
        <CarouselControl ariaLabel={ariaNext} iconName="chevron-right" classes="next-btn" />
      ),
      customPaging: () => <button type="button" aria-label={ariaNext} className="dot-btn" />,
    };

    return (
      <div className="carousel-wrapper">
        <Slider {...{ ...defaultSettings, ...settings }}>
          {slides.map((item, idx) => (
            <SlideComponent {...item} key={idx} isLazyLoading={!!idx} />
          ))}
        </Slider>
      </div>
    );
  }

  return <SlideComponent {...slides?.[0]} />;
};

export default WithCarousel;
