import React, { FC, Fragment } from 'react';
import { graphql } from 'gatsby';
import classNames from 'classnames';

import { Container } from 'layout';
import Button from 'common/Button';
import GatsbyImage from 'common/GatsbyImage';
import DangerouslySetInnerHtml from 'common/DangerouslySetInnerHtml';
import useScreenRecognition from 'hooks/useScreenRecognition';

import { IHomeBannerProps } from './model';

import './HomeBanner.scss';

const HomeBanner: FC<IHomeBannerProps> = ({
  background,
  mobileBackground,
  image,
  mobileImage,
  disclaimer = '',
  description = '',
  cta = [],
  buttonsClassName = '',
  isBigImageMode = false,
  disclaimerOutside,
  isLazyLoading = false,
  isCarouselBackgroundOnly,
  additionalBlockDescription,
  additionalBlockCta,
  additionalBlockImage,
  titleColor,
  isTransparentDisclaimer,
  isTopImage,
}) => {
  const desktopBannerBackground = background?.[0]?.properties;
  const mobileBannerBackground = mobileBackground?.[0]?.properties;
  const desktopImageBanner = image?.[0]?.properties;
  const mobileImageBanner = mobileImage?.[0]?.properties;
  const additionalImage = additionalBlockImage?.[0]?.properties;
  const titleColorValue = titleColor?.[0];

  const { isDesktop, isNotMobile } = useScreenRecognition();

  const bannerImage = isNotMobile ? desktopImageBanner : mobileImageBanner;
  const bannerBackground = isNotMobile ? desktopBannerBackground : mobileBannerBackground;

  const isEmptyDisclaimerBlock = !!bannerImage && !disclaimer;
  const hasAdditionalBlock = additionalBlockDescription && additionalBlockCta?.length;

  const DisclaimerElement = () =>
    disclaimer || isEmptyDisclaimerBlock ? (
      <DangerouslySetInnerHtml
        className={classNames('home-banner__disclaimer', {
          'home-banner__disclaimer--empty': isEmptyDisclaimerBlock,
          'home-banner__disclaimer--transparent': isTransparentDisclaimer,
        })}
        html={disclaimer || ' '}
      />
    ) : null;

  return (
    <div
      className={classNames('home-banner', {
        'home-banner--carousel-bg-only': isCarouselBackgroundOnly,
        'home-banner--additional-block': hasAdditionalBlock,
        'home-banner--top-image-style': isTopImage,
      })}
    >
      <div
        className={classNames('home-banner__background', {
          'home-banner--big-image': isBigImageMode,
        })}
      >
        {bannerBackground?.image && (
          <GatsbyImage
            image={bannerBackground.image}
            alt={bannerBackground?.imageAlt}
            style={{ position: 'absolute' }}
            className="home-banner__background-img"
            isLazyLoading={isLazyLoading}
            fadeIn={false}
          />
        )}

        <Container fluid>
          <div className="home-banner__grid">
            <div className="home-banner__info  home-banner__info--dark-theme">
              {description ? (
                <DangerouslySetInnerHtml
                  className={classNames('home-banner__description', {
                    [`${titleColorValue}`]: titleColorValue,
                  })}
                  html={description}
                />
              ) : null}
              {cta?.length > 0 && (
                <div
                  className={classNames('home-banner__buttons', {
                    [`${buttonsClassName}`]: buttonsClassName,
                  })}
                >
                  {cta.map(({ properties: { link, ariaLabel = '' } }, idx) => {
                    const linkItem = link?.[0];

                    return (
                      <Fragment key={linkItem?.name}>
                        <Button
                          to={linkItem?.url}
                          ariaLabel={ariaLabel}
                          variant={idx === 0 ? 'primary-link' : 'secondary-link'}
                          iconSuffix="chevron-right"
                          target={linkItem?.target}
                        >
                          {linkItem?.name}
                        </Button>
                        {` `}
                      </Fragment>
                    );
                  })}
                </div>
              )}
            </div>

            {hasAdditionalBlock ? (
              <div className="home-banner__info home-banner__info--additional home-banner__info--dark-theme">
                <div className="home-banner__info--text">
                  {additionalBlockDescription ? (
                    <DangerouslySetInnerHtml
                      className="home-banner__description"
                      html={additionalBlockDescription}
                    />
                  ) : null}
                  {additionalBlockCta.length > 0 && (
                    <div
                      className={classNames('home-banner__buttons', {
                        [`${buttonsClassName}`]: buttonsClassName,
                      })}
                    >
                      {additionalBlockCta.map(({ properties: { link, ariaLabel = '' } }, idx) => {
                        const linkItem = link?.[0];

                        return (
                          <Fragment key={linkItem?.name}>
                            <Button
                              to={linkItem?.url}
                              ariaLabel={ariaLabel}
                              variant={idx === 0 ? 'primary-link' : 'secondary-link'}
                              iconSuffix="chevron-right"
                              target={linkItem?.target}
                            >
                              {linkItem?.name}
                            </Button>
                            {` `}
                          </Fragment>
                        );
                      })}
                    </div>
                  )}
                </div>

                {additionalImage?.image ? (
                  <div className="home-banner__info--additional-image">
                    <GatsbyImage
                      image={additionalImage.image}
                      alt={additionalImage?.imageAlt}
                      style={{ position: 'absolute' }}
                      fadeIn={false}
                    />
                  </div>
                ) : null}
              </div>
            ) : null}

            {bannerImage?.image ? (
              <div className="home-banner__image">
                <GatsbyImage
                  image={bannerImage.image}
                  alt={bannerImage.imageAlt}
                  objectFit="contain"
                  className="home-banner__image-img"
                  isLazyLoading={isLazyLoading}
                  fadeIn={false}
                />
              </div>
            ) : null}
          </div>
          {!disclaimerOutside || isDesktop ? <DisclaimerElement /> : null}
        </Container>
        {!disclaimerOutside || isDesktop ? null : <DisclaimerElement />}
      </div>
    </div>
  );
};

export const query = graphql`
  fragment FragmentHomeBanner on THomeBanner {
    properties {
      background {
        properties {
          image {
            ...FragmentGatsbyProps
            gatsbyImage {
              childImageSharp {
                fluid(maxWidth: 1200) {
                  ...GatsbyImageSharpFluid_withWebp_noBase64
                }
              }
            }
          }
          imageAlt
        }
      }
      mobileBackground {
        properties {
          image {
            ...FragmentGatsbyProps
            gatsbyImage {
              childImageSharp {
                fluid(maxWidth: 800) {
                  ...GatsbyImageSharpFluid_withWebp_noBase64
                }
              }
            }
          }
          imageAlt
        }
      }
      image {
        properties {
          image {
            ...FragmentGatsbyProps
            gatsbyImage {
              childImageSharp {
                fluid(maxWidth: 650, webpQuality: 75) {
                  ...GatsbyImageSharpFluid_withWebp_noBase64
                }
              }
            }
          }
          imageAlt
        }
      }
      mobileImage {
        properties {
          image {
            ...FragmentGatsbyProps
            gatsbyImage {
              childImageSharp {
                fluid(maxWidth: 340, webpQuality: 50) {
                  ...GatsbyImageSharpFluid_withWebp_noBase64
                }
              }
            }
          }
          imageAlt
        }
      }
      description
      disclaimer
      cta {
        properties {
          ariaLabel
          link {
            name
            url
            target
          }
        }
      }
      isBigImageMode
      disclaimerOutside
      isCarouselBackgroundOnly
      additionalBlockDescription
      isTopImage
      additionalBlockCta {
        properties {
          ariaLabel
          link {
            name
            url
            target
          }
        }
      }
      additionalBlockImage {
        properties {
          image {
            ...FragmentGatsbyProps
            gatsbyImage {
              childImageSharp {
                fluid(maxWidth: 150, webpQuality: 50) {
                  ...GatsbyImageSharpFluid_withWebp_noBase64
                }
              }
            }
          }
          imageAlt
        }
      }
      titleColor
      isTransparentDisclaimer
    }
  }
`;

export default HomeBanner;
