import React, { FC, useMemo } from 'react';
import { graphql } from 'gatsby';
import { Container, DangerouslySetInnerHtml } from 'layout';
import classNames from 'classnames';
import { UmbracoProductListingBanner } from '@shared/types/umbraco/compositions';
import Button from 'common/Button';
import ProductCard from 'components/ProductCard';
import './ProductListingBanner.scss';
import useShopifyHelper from 'hooks/useShopifyHelper';

const ProductListingBanner: FC<UmbracoProductListingBanner.IData> = ({
  intro,
  links,
  relatedProducts,
  color,
  isBundles,
  lang,
  isShopify,
  cartProductsData,
  isSortByAvailability,
  usePriceSpider,
  isEanProductId,
}) => {
  const { mergeCmsShopifyProducts } = useShopifyHelper({ isShopify, lang });

  const productsList = mergeCmsShopifyProducts(relatedProducts);

  const sortedList = useMemo(() => {
    if (!isSortByAvailability) {
      return productsList;
    }

    const result = [...(productsList || [])];
    const toShow = isBundles ? 3 : 4;

    return result
      .sort((a, b) => {
        if (!a.availableQuantity) return 1;

        if (!b.availableQuantity) return -1;

        return 0;
      })
      .slice(0, toShow);
  }, [JSON.stringify(productsList), isSortByAvailability, isBundles]);

  return (
    <div
      className={classNames('product-listing-banner', {
        [`product-listing-banner--bg-${color?.label}`]: color?.label,
        'product-listing-banner--bundles-theme': isBundles,
      })}
      data-testid="product-listing-banner"
    >
      <Container fluid>
        {intro ? (
          <DangerouslySetInnerHtml className="product-listing-banner__intro" html={intro} />
        ) : null}
        <ul className="product-listing-banner__grid">
          {productsList?.length
            ? sortedList.map((product) => (
                <li key={product.link}>
                  <ProductCard
                    {...product}
                    isBundles={isBundles}
                    isShopify={isShopify}
                    lang={lang}
                    cartProductsData={cartProductsData}
                    usePriceSpider={usePriceSpider}
                    isEanProductId={isEanProductId}
                  />
                </li>
              ))
            : null}
        </ul>
        {links?.length ? (
          <div className="product-listing-banner__links">
            {links.map(({ properties: { link, ariaLabel } }, idx) => (
              <Button
                to={link[0].url}
                ariaLabel={ariaLabel}
                key={link[0].name}
                variant={idx > 0 ? 'secondary-link' : 'primary-link'}
                iconSuffix="chevron-right"
              >
                {link[0].name}
              </Button>
            ))}
          </div>
        ) : null}
      </Container>
    </div>
  );
};

export const query = graphql`
  fragment FragmentProductListingBanner on TProductListingBanner {
    structure
    properties {
      anchorName
      anchorTitle
      showInMenu
      isBundles
      isSortByAvailability
      intro
      color {
        label
        value
      }
      links {
        structure
        properties {
          ariaLabel
          link {
            name
            url
          }
        }
      }
      products {
        name
        url
      }
    }
  }
`;

export default ProductListingBanner;
