import React, { FC, useEffect } from 'react';
import { graphql } from 'gatsby';

import Layout from 'layout/Layout';
import HomeBanner from 'components/HomeBanner';
import InfoBanner from 'components/InfoBanner';
import WideBanner from 'components/WideBanner';
import BodyRenderer from 'common/BodyRenderer';
import SectionWithCarousel from 'components/SectionWithCarousel';
import QuestionsCarousel from 'components/SupportPage/QuestionsCarousel';
import QuestionsBlock from 'components/SupportPage/QuestionsBlock';
import SlimBanner from 'components/SlimBanner';
import ProductListingBanner from 'components/ProductListingBanner';
import SignUp from 'components/SignUp';
import Quiz from 'components/Quiz';
import ImageBanner from 'components/ImageBanner';
import WithCarousel from 'components/WithCarousel';

import { HomepageTypes } from '@shared/types/umbraco/content/homepage';
import { getGtmEvents, EVENT_TYPES, emitGtmEvent } from 'utils/gtmEvents';

import './HomePage.scss';

const elements = {
  'Info Banner': ({ properties }, keyId) => <InfoBanner key={keyId} {...properties} />,
  'Image Banner': ({ properties }, keyId) => <ImageBanner key={keyId} {...properties} />,
  'Signup Placeholder': ({ properties }, keyId) => <SignUp key={keyId} {...properties} />,
  'Info Banner Wide': ({ properties }, keyId) => (
    <WideBanner key={keyId} {...properties} buttons={properties?.cta} />
  ),
  'Product Listing Banner': ({ properties }, keyId) => (
    <ProductListingBanner key={keyId} {...properties} />
  ),
  'Slim banner': ({ properties }, keyId) => <SlimBanner key={keyId} {...properties} />,
  'Quiz Placeholder': ({ properties }, keyId) => <Quiz key={keyId} {...properties} />,
  'Section with carousel': ({ properties }, keyId) => {
    const { text, card, color, carouselMode, button, waiSettings, verticalCardMode } = properties;
    const questionCards = card.map((item) => item.properties);

    return (
      <SectionWithCarousel
        key={keyId}
        text={text}
        color={color}
        cardsBlock={
          carouselMode ? (
            <QuestionsCarousel
              questionCards={questionCards}
              ariaNext={waiSettings?.ariaNext}
              ariaPrev={waiSettings?.ariaPrev}
              isVerticalCardMode={verticalCardMode}
            />
          ) : (
            <QuestionsBlock questionCards={questionCards} isVerticalCardMode={verticalCardMode} />
          )
        }
        button={button}
      />
    );
  },
};

const HomePage: FC<{ data: HomepageTypes.IProperties }> = ({ data, pathContext }) => {
  const banner = data?.homepage?.banner?.map(({ properties }) => ({ ...properties }));
  const body = data?.homepage?.body;
  const relatedProducts = data?.productBundles;
  const {
    seoMetaKeywords,
    seoMetaTitle,
    seoMetaDescription,
    seoExternalHreflangs,
    seoNoIndex,
    alternateUrls,
  } = data?.homepage;
  const { siteSettings, brandSettings, header, footer, waiSettings, purchaseSettings, warning } =
    data?.homepage?.defaultCompositions;
  const singupFormBaner = data?.homepage?.defaultCompositions?.singupFormBaner;
  const isShopify = data?.homepage?.defaultCompositions?.siteSettings?.isShopify;
  const lang = data?.homepage?.defaultCompositions?.siteSettings?.lang;
  const productsLinks = pathContext?.productBundlesLinks || [];
  const quizData = data?.quizData?.nodes?.[0];
  const sortedProducts = productsLinks
    .map((el) => relatedProducts?.nodes?.find((prodItem) => prodItem.link === el))
    .filter(Boolean);
  const { productBundles } = data;
  const bgImage = banner?.[0].background?.[0]?.properties;

  const {
    [EVENT_TYPES.PAGE_VIEW]: gtmPageViewEvent,
    [EVENT_TYPES.SUBMIT_SEARCH]: gtmSearchSubmitEvent,
  } = getGtmEvents(data?.homepage?.gtmEvents);

  useEffect(() => {
    gtmPageViewEvent && emitGtmEvent(gtmPageViewEvent);
  }, []);

  return (
    <Layout
      {...{
        footer,
        header,
        seoMetaKeywords,
        seoMetaTitle,
        seoNoIndex,
        seoMetaDescription,
        seoExternalHreflangs,
        siteSettings,
        brandSettings,
        purchaseSettings,
        skipText: waiSettings?.skipText,
        warning,
        link: pathContext.link,
        searchUrl: pathContext.searchUrl,
        alternateUrls,
        imageUrl: bgImage?.image?.fallbackUrl,
        imageAlt: bgImage?.imageAlt,
        gtmSearchSubmitEvent,
      }}
    >
      <h1 className="visual-hidden">{seoMetaTitle}</h1>
      <WithCarousel
        slides={banner}
        slideComponent={HomeBanner}
        ariaNext={waiSettings.ariaNext}
        ariaPrev={waiSettings.ariaPrev}
      />
      {body?.length ? (
        <div className="home__body">
          <BodyRenderer
            bodyData={body}
            bodyStructure={elements}
            bodyItemProps={{
              relatedProducts: { nodes: sortedProducts },
              waiSettings,
              singupFormBaner,
              isShopify,
              lang,
              cartProductsData: productBundles,
              quizData,
              usePriceSpider: siteSettings?.usePriceSpider,
              isEanProductId: siteSettings?.isEanProductId,
            }}
          />
        </div>
      ) : null}
    </Layout>
  );
};

export const query = graphql`
  query HomePage($productBundlesLinks: [String], $link: String = "", $lang: String = "") {
    homepage(link: { eq: $link }) {
      seoMetaTitle
      seoMetaDescription
      seoMetaKeywords
      seoNoIndex
      seoExternalHreflangs {
        key
        value
      }
      alternateUrls {
        lang
        url
        path
      }
      defaultCompositions {
        footer {
          ...FragmentFooter
        }
        header {
          ...FragmentHeader
        }
        waiSettings {
          skipText
          ariaPrev
          ariaNext
        }
        siteSettings {
          ...FragmentSiteSettingsComposition
        }
        brandSettings {
          ...FragmentBrandSettingsComposition
        }
        singupFormBaner {
          ...FragmentSignUpBanner
        }
        purchaseSettings {
          ...FragmentPurchaseSettings
        }
        warning {
          ...FragmentWarning
        }
      }
      banner {
        ...FragmentHomeBanner
      }
      body {
        ... on TImageBanner {
          ...FragmentImageBanner
        }
        ... on TInfoBanner {
          ...FragmentInfoBanner
        }
        ... on TInfoBannerWide {
          ...FragmentWideBanner
        }
        ... on TSectionwithcarousel {
          ...FragmentSectionWithCarousel
        }
        ... on TSlimbanner {
          ...FragmentSlimBanner
        }
        ... on TProductListingBanner {
          ...FragmentProductListingBanner
        }
        ... on TSignupPlaceholder {
          ...FragmentSignUp
        }
        ... on TQuizPlaceholder {
          ...FragmentQuiz
        }
      }
      gtmEvents {
        ...FragmentGtmEvents
      }
    }
    productBundles: allBundle(filter: { link: { in: $productBundlesLinks } }) {
      nodes {
        useRedirects
        productInfo
        link
        tags {
          isCategory
          color {
            label
          }
          isLabel
          title
        }
        bundleProducts {
          link
          quantity
        }
        title
        shopifyId
        skuId
        ean
        bundleProductsQuantity
        shortDescription
        cardDescription
        images: imageForCart {
          properties {
            imageAlt
            image {
              ...FragmentGatsbyProps
              gatsbyImage {
                childImageSharp {
                  fluid(maxWidth: 200, srcSetBreakpoints: [100, 400]) {
                    ...GatsbyImageSharpFluid_withWebp
                  }
                }
              }
            }
          }
        }
        cardImage {
          properties {
            imageAlt
            image {
              ...FragmentGatsbyProps
              gatsbyImage {
                childImageSharp {
                  fluid(maxWidth: 200) {
                    ...GatsbyImageSharpFluid_withWebp
                  }
                }
              }
            }
          }
        }
      }
    }
    quizData: allQuiz(filter: { lang: { eq: $lang } }) {
      nodes {
        pageName
        link
        quizResult {
          properties {
            resultDescription
            restart
            ariaRestart
            limit
          }
        }
        relatedSlides {
          url
          properties {
            step
            question
            options {
              structure
              properties {
                description
                icon
                nextSlide {
                  url
                }
                tags {
                  title
                }
                title
              }
            }
          }
        }
      }
    }
  }
`;

export default HomePage;
